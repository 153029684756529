.container {
  padding: calc(6 * var(--REM)) 0 calc(10 * var(--REM));
  border-width: 0;
  border-bottom-width: calc(1 * var(--REM));
  border-color: var(--color-gray7);
  border-style: solid;
  margin-bottom: calc(16 * var(--REM));

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(8 * var(--REM));

    .row {
      display: flex;
      flex: 1;
      align-items: center;

      .myQna {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--color-gray2);
        border-radius: calc(4 * var(--REM));
        margin-right: calc(2 * var(--REM));
        padding: calc(2 * var(--REM)) calc(6 * var(--REM));

        font-size: calc(10 * var(--REM));
        line-height: 130%;
        font-weight: 500;
        color: var(--color-white);
      }
      .title {
        display: flex;
        flex: 1;

        span {
          font-size: calc(14 * var(--REM));
          line-height: 130%;

          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        svg {
          margin-left: calc(1 * var(--REM));
        }
      }
    }
    .deleteBtn {
      font-size: calc(14 * var(--REM));
    }
  }
  .bottomContainer {
    display: flex;
    align-items: center;

    .isAnswer {
      font-size: calc(14 * var(--REM));
    }
    .nickname {
      font-size: calc(14 * var(--REM));
      color: var(--color-gray5);
    }
    .date {
      font-size: calc(14 * var(--REM));
      color: var(--color-gray5);
    }
    .bar {
      height: calc(10 * var(--REM));
      width: calc(1 * var(--REM));
      background: var(--color-gray7);
      margin: 0 calc(8 * var(--REM));
    }
  }
  .answerContainer {
    max-height: 0;
    transition: all 0.3s ease;
    overflow: hidden;

    &.open {
      max-height: 1000px;
      background: var(--color-gray7);
      margin-top: calc(16 * var(--REM));
    }

    > div {
      padding: calc(12 * var(--REM)) calc(12 * var(--REM)) calc(16 * var(--REM));
    }

    .titleRow {
      display: flex;
      margin-bottom: calc(8 * var(--REM));

      .badge {
        padding: calc(2 * va(--REM)) calc(6 * va(--REM));
        background: var(--color-gray1);
        margin-right: calc(10 * var(--REM));

        font-size: calc(10 * var(--REM));
        line-height: 130%;
        font-weight: 500;
        color: var(--color-white);
      }
      .date {
        font-size: calc(12 * var(--REM));
        line-height: 130%;
        font-weight: 500;
        color: var(--color-gray4);
      }
    }
    .answer {
      font-size: calc(14 * var(--REM));
      line-height: 150%;
      word-break: keep-all;
      white-space: pre-wrap;
    }
  }
}
