.contentView {
  display: flex;
  flex: 1;
  padding: calc(1 * var(--REM)) calc(3 * var(--REM));

  .contentDot {
    line-height: calc(20 * var(--REM));
    padding: 0 calc(5 * var(--REM));
    color: var(--color-gray3);
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    color: var(--color-gray4);
    margin-bottom: calc(2 * var(--REM));
  }

  .circle {
    display: flex;
    top: calc(4 * var(--REM));
    background-color: var(--color-gray7);
    align-items: center;
    justify-content: center;
    margin-right: calc(2 * var(--REM));

    span {
      font-size: calc(12 * var(--REM));
    }
  }
}
