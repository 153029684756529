.container {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  .headerContainer {
    flex: 1;
    background-color: var(--color-white);

    .headerTab {
      flex: 1;
      padding: 0 calc(16 * var(--REM));
      display: flex;
      flex-direction: row;
      height: 44px;

      .item {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        font-size: calc(14 * var(--REM));
        line-height: 150%;
        font-weight: 500;
        color: var(--color-gray3);
        text-decoration: none;
        border-top: calc(2 * var(--REM)) solid var(--color-white);

        &.active {
          border-top: calc(2 * var(--REM)) solid var(--color-green);
          color: var(--color-green);
          font-weight: 600;
        }
      }

      a {
        &:hover,
        &:active,
        &:visited,
        &:link,
        &:focus,
        &::selection,
        &:target {
          text-decoration: none;
          color: inherit;
          background-color: transparent;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }

  .contentContainer {
    overflow-x: hidden;
    overflow-y: scroll;

    .contentHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: calc(16 * var(--REM)) 0 calc(18 * var(--REM)) 0;
      margin: 0 calc(20 * var(--REM));
      border-bottom: calc(0.5 * var(--REM)) solid var(--color-green);

      svg {
        margin-bottom: calc(11 * var(--REM));
      }

      span {
        font-size: calc(16 * var(--REM));
        letter-spacing: calc(4.32 * var(--REM));
        color: var(--color-green);
      }
    }

    .titleContainer {
      text-align: center;
      margin: calc(20 * var(--REM));
      margin-bottom: calc(52 * var(--REM));

      .title {
        font-family: "AritaBuri";
        font-size: calc(24 * var(--REM));
        line-height: 130%;
        color: var(--color-green);
        font-weight: 500;
        letter-spacing: -0.048px;
        white-space: pre-wrap;
      }
      .subTitle {
        margin-top: calc(12 * var(--REM));
        font-size: calc(16 * var(--REM));
        line-height: 150%;
        color: var(--color-gray4);
        white-space: pre-wrap;
      }
    }

    .instagramContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: calc(48 * var(--REM)) 0;

      .btn {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(10 * var(--REM)) calc(44 * var(--REM));
        border: calc(1 * var(--REM)) solid var(--color-gray6);
        border-radius: calc(8 * var(--REM));

        .svgContainer {
          width: calc(24 * var(--REM));
          height: calc(24 * var(--REM));
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          margin-left: calc(4 * var(--REM));
          size: calc(14 * var(--REM));
        }
      }
    }

    .contents {
      margin: 0 calc(20 * var(--REM));

      .detailItem {
        margin-bottom: calc(32 * var(--REM));

        .title {
          font-size: calc(20 * var(--REM));
          line-height: 130%;
          font-weight: 600;
          color: var(--color-gray1);
          margin-bottom: calc(16 * var(--REM));
          white-space: pre-wrap;
        }
        img {
          width: 100%;
          border-radius: calc(16 * var(--REM));
          margin-bottom: calc(12 * var(--REM));
        }
        .content {
          font-size: calc(16 * var(--REM));
          line-height: 150%;
          color: var(--color-gray3);
          white-space: pre-wrap;
        }

        &:first-child {
          .title {
            margin-bottom: calc(12 * var(--REM));
          }
          img {
            margin-bottom: calc(30 * var(--REM));
          }
        }

        .sliderDots {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: calc(16 * var(--REM));

          &.isPlusBottom {
            bottom: calc(80 * var(--REM) * -1);
          }

          ul {
            padding: 0;
            margin: 0;
            display: flex;

            li {
              display: block;
              width: calc(4 * var(--REM));
              height: calc(4 * var(--REM));
              border-radius: calc(32 * var(--REM));
              background-color: var(--color-gray1);
              opacity: 0.2;
              margin: 0 calc(2 * var(--REM));

              button {
                display: none;
              }

              &:global(.slick-active) {
                width: calc(12 * var(--REM));
                opacity: 1;
              }
            }
          }
        }

        *:focus {
          outline: 0;
          outline: none;
        }
      }

      .productSection {
        margin-bottom: calc(32 * var(--REM));

        .title {
          font-size: calc(20 * var(--REM));
          line-height: 130%;
          font-weight: 600;
          color: var(--color-gray1);
          margin-bottom: calc(16 * var(--REM));
        }

        .productContents {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .productItem {
            width: calc(50% - calc(6 * var(--REM)));
            margin-bottom: calc(16 * var(--REM));

            &.even {
              margin-right: calc(6 * var(--REM));
            }
            &.odd {
              margin-left: calc(6 * var(--REM));
            }
          }
        }
      }
    }
  }

  .footerContainer {
    padding: calc(8 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM))
      calc(20 * var(--REM));
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      width: calc(32 * var(--REM));
      height: calc(32 * var(--REM));
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .divider {
      width: calc(1 * var(--REM));
      height: calc(100% - calc(12 * var(--REM)));
      margin: 0 calc(12 * var(--REM));
      background-color: var(--color-gray7);
    }

    .footerBtn {
      flex: 1;
      margin-left: calc(12 * var(--REM));
      background-color: var(--color-green);
      border-radius: calc(8 * var(--REM));
      padding: calc(16 * var(--REM)) 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-white);
      font-weight: 700;
      text-wrap: nowrap;
      overflow: hidden;
    }
  }
}
