.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .left {
    min-width: calc(20 * var(--REM));

    .leftContent {
      position: absolute;
      top: 0;
      left: calc(8 * var(--REM));

      .leftContainer {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 0;

        .item {
          display: flex;
          align-items: center;

          .division {
            width: calc(1 * var(--REM));
            height: calc(16 * var(--REM));
            background: var(--color-gray5);
          }
        }
      }
    }
  }

  .center {
    flex: 1;
    width: 100%;
    margin: 0 calc(40 * var(--REM));
  }

  .right {
    min-width: calc(20 * var(--REM));

    .rightContent {
      position: absolute;
      top: 0;
      right: calc(6 * var(--REM));

      .rightContainer {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;

        .item {
          display: flex;
          align-items: center;

          .division {
            width: calc(1 * var(--REM));
            height: calc(16 * var(--REM));
            background: var(--color-gray5);
          }
        }
      }
    }
  }
}

.backIcon {
  width: calc(44 * var(--REM));
  height: calc(44 * var(--REM));
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.icon {
  height: calc(44 * var(--REM));
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
