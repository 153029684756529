.container {
  .imgContainer {
    position: relative;
    display: flex;
    margin-bottom: calc(8 * var(--REM));

    .img {
      width: 100%;
      aspect-ratio: calc(162 / 190);
      border-radius: calc(16 * var(--REM));
    }

    .cart {
      position: absolute;
      bottom: calc(8 * var(--REM));
      right: calc(8 * var(--REM));
      width: calc(44 * var(--REM));
      height: calc(44 * var(--REM));
      border-radius: 100%;
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
      background-color: var(--color-white);
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: calc(17 * var(--REM));
        height: calc(17 * var(--REM));
      }
    }

    .mainBadgeContainer {
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: calc(8 * var(--REM));
      left: calc(10 * var(--REM));

      .badgeItem {
        margin-right: calc(2 * var(--REM));

        img {
          width: calc(24 * var(--REM));
        }
      }
    }
  }

  .title {
    margin-bottom: calc(6 * var(--REM));
  }

  .priceContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    .percent {
      margin-right: calc(4 * var(--REM));
    }

    .price {
      color: var(--color-black);
      font-size: calc(14 * var(--REM));
      font-weight: 700;
      line-height: 130%;
    }

    .viewPrice {
      color: var(--color-gray5);
      font-size: calc(12 * var(--REM));
      font-weight: 400;
      line-height: 150%;
      margin-left: calc(4 * var(--REM));
      text-decoration: line-through;
    }

    .soldOut {
      color: var(--color-gray4);
      font-size: calc(14 * var(--REM));
      font-weight: 700;
      line-height: 130%;
    }
  }

  .reviewContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: calc(4 * var(--REM));

    .starIcon {
      width: calc(12 * var(--REM));
      height: calc(12 * var(--REM));
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: calc(2 * var(--REM));
    }

    .score {
      font-size: calc(12 * var(--REM));
      line-height: 150%;
      font-weight: 700;
    }

    .reviewCnt {
      margin-left: calc(2 * var(--REM));
      font-size: calc(12 * var(--REM));
      line-height: 150%;
      color: var(--color-gray3);
    }
  }

  .badgeContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;

    .badgeItem {
      padding: calc(4 * var(--REM)) calc(6 * var(--REM));
      border-radius: calc(4 * var(--REM));
      margin-right: calc(2 * var(--REM));
      margin-top: calc(4 * var(--REM));
      flex-direction: row;
      display: flex;
      align-items: center;

      svg {
        margin-right: calc(3 * var(--REM));
      }

      span {
        font-size: calc(10 * var(--REM));
        font-weight: 600;
        line-height: 130%;
      }
    }
  }
}

.emptyProductImage {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray7);
  height: 100%;
}
