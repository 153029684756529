.wrap {
  width: var(--DEVICE-WIDTH);
  height: var(--DEVICE-HEIGHT);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .modal {
    max-width: var(--DEVICE-WIDTH);
    width: calc(var(--DEVICE-WIDTH) - calc(47 * var(--REM)));
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(32 * var(--REM)) 0 calc(14 * var(--REM));
    border-radius: calc(16 * var(--REM));
    position: relative;

    .subTitle {
      margin-top: calc(4 * var(--REM));
    }
    .content {
      margin-top: calc(8 * var(--REM));
    }

    .btnContainer {
      display: flex;
      flex: 1;
      width: calc(100% - calc(48 * var(--REM)));
      justify-content: space-between;
      margin: calc(20 * var(--REM)) 0 calc(10 * var(--REM));

      .btn {
        height: calc(56 * var(--REM));
        border-radius: calc(12 * var(--REM));
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;

        &.leftBtn {
          background: var(--color-gray8);
          margin-right: calc(4 * var(--REM));
        }
        &.rightBtn {
          background: var(--color-green);
          margin-left: calc(4 * var(--REM));
        }
        &.oneBtn {
          background: var(--color-green);
        }
      }
    }
  }
}
