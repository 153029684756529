.topTabList {
  display: flex;
  padding: 0 calc(16 * var(--REM));
  background-color: var(--color-white);

  .tabItem {
    flex: 1;
    display: flex;
    background-color: var(--color-white);
    border-bottom: calc(2 * var(--REM)) solid var(--color-white);
    justify-content: center;
    align-items: center;
    height: calc(42 * var(--REM));

    &.active {
      border-bottom-color: var(--color-green);
    }
  }
}

.scroll {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bannerContainer {
  position: relative;

  :global(.slick-list) {
    overflow: hidden;

    :global(.slick-slide) > div {
      display: flex;

      .bannerItem {
        position: relative;

        img {
          width: 100%;
        }
        .textContainer {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          .subTitle {
            padding-top: calc(8 * var(--REM));
          }
        }
        .dateContainer {
          position: absolute;
          bottom: calc(21 * var(--REM));
          left: calc(20 * var(--REM));
        }

        &.simple {
          .textContainer {
            bottom: calc(12 * var(--REM));
            left: calc(12 * var(--REM));
          }
        }
        &:not(.simple) {
          .textContainer {
            padding: calc(37 * var(--REM)) 0 calc(20 * var(--REM))
              calc(24 * var(--REM));
          }
        }
      }
    }
  }

  .bannerPage {
    position: absolute;
    bottom: calc(16 * var(--REM));
    right: calc(13 * var(--REM));
    background: rgba(0, 0, 0, 0.4);
    border-radius: calc(100 * var(--REM));
    padding: calc(4 * var(--REM)) calc(10 * var(--REM));
  }
}

.etcTabContainer {
  display: flex;
  position: relative;

  &.isImg {
    min-height: calc(216 * var(--REM));
    img {
      height: calc(244 * var(--REM));
    }
    .textContainer {
      position: absolute;
      padding: calc(30 * var(--REM)) 0 0 calc(20 * var(--REM));
    }
  }

  &:not(.isImg) {
    padding: calc(32 * var(--REM)) calc(20 * var(--REM)) 0;
  }
}

.categoryContainer {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: calc(20 * var(--REM)) calc(14 * var(--REM)) calc(12 * var(--REM));

  .categoryItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 calc(6 * var(--REM));

    .image {
      background-color: var(--color-gray8);
      border-radius: calc(16 * var(--REM));
      width: calc(56 * var(--REM));
      height: calc(56 * var(--REM));
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: calc(4 * var(--REM));
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.productSkeleton {
  .title {
    padding: calc(32 * var(--REM)) calc(20 * var(--REM));
  }
  .itemContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: 0 calc(14 * var(--REM));

    .item {
      display: flex;
      width: 50%;
      flex-direction: column;
      margin-bottom: calc(32 * var(--REM));

      > div {
        flex: 1;
        padding: 0 calc(6 * var(--REM));
      }
    }
  }
}

.productSection {
  flex: 1;
  // display: flex;
  // flex-direction: column;

  &.emptySection {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentTitle {
    padding: 0 calc(20 * var(--REM));
    &.view1and2 {
      padding-top: calc(32 * var(--REM));
      padding-bottom: calc(20 * var(--REM));
    }
    &.view3 {
      padding-bottom: calc(16 * var(--REM));
    }
  }
  .view3Content {
    background-color: var(--color-gray8);
    padding: calc(20 * var(--REM)) 0;
    margin-top: calc(32 * var(--REM));
  }

  .viewType1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 calc(20 * var(--REM));

    .item {
      width: 50%;
      margin-bottom: calc(24 * var(--REM));

      &:nth-child(even) > div {
        padding-left: calc(6 * var(--REM));
      }
      &:nth-child(odd) > div {
        padding-right: calc(6 * var(--REM));
      }
    }
  }
  .viewType2 {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    padding: 0 calc(20 * var(--REM));

    .item {
      margin: 0 0 calc(24 * var(--REM)) calc(10 * var(--REM));

      &:first-child {
        margin-left: 0;
      }

      > div {
        width: calc(142 * var(--REM));
      }
    }
  }
  .viewType3 {
    margin: 0 calc(20 * var(--REM));

    .container {
      border-radius: calc(16 * var(--REM));
      overflow: hidden;
      position: relative;
      background-color: var(--color-white);
      box-shadow: 0 0 5px 1px var(--color-gray5);

      .mainImg {
        display: flex;
        img {
          width: 100%;
          aspect-ratio: calc(335 / 178);
        }
      }
      .content {
        padding: calc(16 * var(--REM));

        .recipeContainer {
          display: flex;
          justify-content: space-between;
          padding: calc(8 * var(--REM)) calc(8 * var(--REM))
            calc(8 * var(--REM)) calc(14 * var(--REM));
          margin-top: calc(16 * var(--REM));
          border: calc(1 * var(--REM)) solid var(--color-gray7);
          border-radius: calc(12 * var(--REM));

          .title {
            display: flex;
            align-items: center;
            padding-right: calc(10 * var(--REM));

            > div {
              white-space: pre;
            }
          }

          .itemList {
            display: flex;
            flex: 1;
            justify-content: flex-end;

            .item {
              display: flex;
              width: calc(60 * var(--REM));
              height: calc(60 * var(--REM));
              margin-left: calc(4 * var(--REM));
              border-radius: calc(8 * var(--REM));
              overflow: hidden;
              position: relative;

              img {
                border: calc(1 * var(--REM)) solid var(--color-gray7);
                border-radius: calc(8 * var(--REM));
              }

              .more {
                display: flex;
                position: absolute;
                flex: 1;
                background-color: rgba(0, 0, 0, 0.3);
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        background-color: var(--color-green);
        padding: calc(13 * var(--REM)) 0;

        > div {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
