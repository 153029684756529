.container {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  .headerContainer {
    margin: 0 calc(20 * var(--REM));
    padding: calc(18 * var(--REM)) 0;
    border-bottom: calc(1 * var(--REM)) solid var(--color-gray7);

    .dateRow {
      display: flex;
      align-items: center;
      margin-top: calc(4 * var(--REM));

      .date {
        margin-right: calc(4 * var(--REM));
      }
    }
  }

  .contentContainer {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: calc(20 * var(--REM)) calc(24 * var(--REM));

    .img {
      margin-bottom: calc(12 * var(--REM));
    }
  }
}
