@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.container {
  display: flex;
  flex: 1;
  justify-content: center;

  transition: margin 50ms linear;

  &.active {
    margin-bottom: 30px;
  }

  .loader {
    .loading {
      height: calc(10 * var(--REM));
      width: calc(10 * var(--REM));
      border: calc(1 * var(--REM)) solid var(--color-white);
      border: {
        right-color: var(--color-green);
        top-color: var(--color-green);
        radius: 100%;
      }
      animation: spin 1s infinite linear;
    }
  }
}
