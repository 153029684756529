.modal {
  max-width: var(--DEVICE-WIDTH);
  width: var(--DEVICE-WIDTH);
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: calc(32 * var(--REM)) 0 calc(14 * var(--REM));
  border-radius: calc(16 * var(--REM)) calc(16 * var(--REM)) 0 0;
  position: relative;

  .closeBtn {
    display: flex;
    position: absolute;
    top: calc(8 * var(--REM));
    right: calc(8 * var(--REM));
    align-items: center;
    justify-content: center;
    width: calc(48 * var(--REM));
    height: calc(48 * var(--REM));
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: calc(42 * var(--REM)) calc(20 * var(--REM)) calc(32 * var(--REM));

    .textContainer {
      margin-top: calc(30 * var(--REM));
    }
  }

  .bottom {
    width: 100%;

    .bottomBtn {
      margin: 0 calc(20 * var(--REM)) calc(20 * var(--REM));
      background-color: var(--color-green);
      border-radius: calc(12 * var(--REM));
      padding: calc(16 * var(--REM));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
