.tootip {
  display: flex;
  position: relative;

  .box {
    display: flex;
    padding: calc(12 * var(--REM));
    border-radius: calc(12 * var(--REM));
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    position: relative;

    .arrowView {
      position: absolute;
      rotate: 45deg;

      &.bottom {
        bottom: calc(-6 * var(--REM));
      }
      &.top {
        top: calc(-6 * var(--REM));
      }
      &.left {
        left: calc(-6 * var(--REM));
      }
      &.right {
        right: calc(-6 * var(--REM));
      }
    }

    .close {
      display: flex;
      padding-left: calc(8 * var(--REM));
    }
  }
}
