.signOnCouponSection {
  width: 100%;
  position: relative;
  bottom: 0;
  display: flex;
  justify-content: space-between;

  .container {
    display: flex;
    flex-direction: row;
    padding: calc(13 * var(--REM)) calc(16 * var(--REM));
    justify-content: center;
    align-items: center;
    background-color: var(--color-black);
    width: 100%;

    .text {
      display: flex;
      font-size: calc(14 * var(--REM));
      line-height: 150%;
      color: var(--color-gray3);
      word-break: keep-all;
      flex-wrap: wrap;
    }
  }
}
