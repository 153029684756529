.container {
  border-bottom: calc(1 * var(--REM)) solid var(--color-gray7);
  padding: calc(16 * var(--REM)) 0;

  .topContainer {
    margin-bottom: calc(8 * var(--REM));

    .titleRow {
      display: flex;
      flex: 1;
      align-items: center;

      .userRow {
        display: flex;
        flex: 1;
        align-items: flex-start;

        img {
          width: calc(40 * var(--REM));
          aspect-ratio: 1;
          border-radius: 100%;
          margin-right: calc(4 * var(--REM));
        }
        .starContainer {
          margin-bottom: calc(4 * var(--REM));
          position: relative;

          .emptyStar {
            position: absolute;
            top: 0;
          }
          .fillStar {
            position: relative;
            width: calc(80 * var(--REM));
            > div {
              overflow: hidden;
            }
          }
        }
        .bottomRow {
          display: flex;
          align-items: center;

          .nickname {
            font-size: calc(12 * var(--REM));
            line-height: 130%;
            font-weight: 600;
            color: var(--color-gray1);
          }
          .date {
            font-size: calc(12 * var(--REM));
            font-weight: 500;
            color: var(--color-gray3);
          }
          .buyCnt {
            font-size: calc(12 * var(--REM));
            line-height: 130%;
            color: var(--color-gray3);
            font-weight: 500;
          }
        }
      }
    }
    .editBtn {
      width: calc(50 * var(--REM));
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      font-size: calc(12 * var(--REM));
      line-height: 130%;
      font-weight: 500;
      color: var(--color-gray5);
    }
    .declarationBtn {
      width: calc(50 * var(--REM));
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      font-size: calc(12 * var(--REM));
      line-height: 130%;
      font-weight: 500;
      color: var(--color-gray5);
    }
  }
  .selectedOption {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    padding: calc(12 * var(--REM));
    margin-bottom: calc(8 * var(--REM));
    background: var(--color-gray8);
    border-radius: calc(16 * var(--REM));

    .item {
      display: flex;
      align-items: center;
      .categoryTitle {
        font-size: calc(12 * var(--REM));
        line-height: 150%;
        color: var(--color-gray5);
        margin-right: calc(4 * var(--REM));
      }
      .optionTitle {
        font-size: calc(12 * var(--REM));
        line-height: 150%;
      }
    }
  }
  .content {
    display: flex;

    span {
      font-size: calc(14 * var(--REM));
      line-height: 150%;
      font-weight: 500;
      word-break: keep-all;
      white-space: pre-wrap;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
      overflow: hidden;
    }
  }
  .photoContainer {
    display: flex;
    margin-top: calc(16 * var(--REM));
    height: calc(165 * var(--REM));
    border-radius: calc(16 * var(--REM));
    overflow: hidden;

    .item {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: calc(165 * var(--REM));
      overflow: hidden;
      position: relative;

      &:not(:first-child) {
        margin-left: calc(4 * var(--REM));
      }
      .more {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);

        font-size: calc(32 * var(--REM));
        line-height: 150%;
        color: var(--color-white);
        font-weight: 500;
      }

      img {
        width: 100%;
        height: calc(165 * var(--REM));
      }
    }
  }
}

.dot {
  width: calc(2 * var(--REM));
  height: calc(2 * var(--REM));
  background-color: var(--color-gray5);
  border-radius: 100%;
  margin: 0 calc(4 * var(--REM));
}
