.container {
  max-width: var(--DEVICE-WIDTH);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  background: var(--color-white);
  justify-content: space-between;

  .content {
    flex: 1;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
  }
}
