.container {
  position: relative;

  &.hide {
    display: none;
    overflow: hidden;
    height: 0;
  }

  .wrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 99;

    width: 100%;
    height: var(--layout-nav-height);

    background: #fff;
    color: var(--color-gray-100);
    font-size: 12px;
    font-weight: 500;

    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);

    .nav {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      .navItem {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .imgContainer {
          margin-bottom: 4px;
          width: 24px;
          height: 24px;
          text-align: center;

          img {
            width: 80%;
          }
        }
      }
    }
  }

  .safeAreaBottom {
    position: relative;
    z-index: 100;
    overflow: hidden;
    background-color: var(--color-white);
  }
}
