:root {
  /* color */
  --color-green: #003824;

  --color-black: #000000;
  --color-gray: #bebebe;
  --color-gray1: #222222;
  --color-gray2: #333333;
  --color-gray3: #4f4f4f;
  --color-offblack: #4f4f4f;
  --color-gray4: #828282;
  --color-gray5: #bdbdbd;
  --color-gray6: #e0e0e0;
  --color-gray7: #f2f2f2;
  --color-gray8: #f9f9f9;
  --color-grayWarm: #9b9b94;
  --color-white: #ffffff;

  --color-ivory: #fff4e8;

  --color-subBasicColor: #f9f1dc;
  --color-subPointColor4: #eef3f1;
  --color-lightGreenColor: #eaf2e3;
  --color-gray7A7A7A: #7a7a7a;
  --color-gray888888: #888888;
  --color-grayE1E1E1: #e1e1e1;
  --color-redLight: #ff8e89;
  --color-blueDark: #4143df;
  --color-blue: #007aff;
  --color-blueLight: #eff4ff;
  --color-yellow: #f8cf3e;
  --color-ivory2: #fffbf1;
  --color-kakaoColor: #ffde00;
  --color-delivery: #39df8f;
  --color-red: #ff3737;
  --color-red400: #ff5d5d;
  --color-red500: #f24147;
  --color-redDD1616: #dd1616;
  --color-redFFF4F4: #fff4f4;
  --color-baige: #f5eee8;
  --color-orange: #fc845e;
  --color-orangeSub: #ff6a3c;
  --color-orangeSub2: #ff6534;

  --color-primary: #007bff;
  --color-secondary: #6c757d;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --color-warning: #ffc107;
  --color-info: #17a2b8;

  --color-grayC6C6D4: #c6c6d4;

  /* layout */
  --layout-header-height: 50px;
  --layout-nav-height: 60px;

  --PC-WIDTH: 1000px;
  --MAX-WIDTH-NUMBER: 430;
  --MAX-WIDTH: calc(var(--MAX-WIDTH-NUMBER) * 1px);
  --DEVICE-WIDTH: min(100vw, var(--MAX-WIDTH));
  --DEVICE-HEIGHT: 100vh;
  /* prettier-ignore */
  /* --REM: calc(var(--DEVICE-WIDTH)/var(--MAX-WIDTH-NUMBER)); */
  --REM: 1px;
}

* {
  font-family: "SUIT", sans-serif;
  scroll-behavior: smooth;

  -ms-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}

.center {
  text-align: center;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
}

.slick-list {
  *:focus {
    outline: 0;
    outline: none;
  }
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
