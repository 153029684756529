.container {
  background-color: var(--color-gray8);
  padding: 26px 20px 82px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .linkContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .descContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: row;
      gap: 4px;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .division {
    width: 1px;
    height: 10px;
    background-color: var(--color-gray4);
  }
}
