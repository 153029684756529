.floatingContainer {
  position: absolute;
  bottom: calc(-32 * var(--REM));
  opacity: 0;
  right: calc(20 * var(--REM));
  z-index: 999;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .toTopIcon {
    width: calc(32 * var(--REM));
    height: calc(32 * var(--REM));
    border-radius: 100%;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.show {
    bottom: calc(20 * var(--REM));
    opacity: 1;
  }
}

.container {
  padding: calc(20 * var(--REM)) calc(20 * var(--REM)) calc(24 * var(--REM));
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  display: block;
  position: relative;

  .bannerContainer {
    margin-bottom: calc(20 * var(--REM));
    width: 100%;
    aspect-ratio: calc(336 / 80);
    overflow: hidden;
    position: relative;
    display: flex;

    .textContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      left: calc(16 * var(--REM));

      > :not(:first-child) {
        margin-top: calc(4 * var(--REM));
      }
    }
  }
}

.itemContainer {
  margin-bottom: calc(48 * var(--REM));

  .profileContainer {
    display: flex;
    align-items: center;
    margin-bottom: calc(8 * var(--REM));

    .profileImgContainer {
      width: calc(32 * var(--REM));
      height: calc(32 * var(--REM));
      border-radius: 100%;
      overflow: hidden;
      margin-right: calc(8 * var(--REM));

      img {
        width: 100%;
        height: 100%;
      }
    }
    .profileText {
      .profileName {
        font-size: calc(14 * var(--REM));
        margin-bottom: calc(2 * var(--REM));
        font-weight: 500;
      }
      .profileTime {
        font-size: calc(12 * var(--REM));
        color: var(--color-gray4);
      }
    }
  }

  .imgContainer {
    margin-bottom: calc(10 * var(--REM));
    position: relative;

    :global(.slick-list) {
      border-radius: calc(8 * var(--REM));
      overflow: hidden;

      :global(.slick-slide) > div {
        display: flex;

        img {
          width: 100%;
          aspect-ratio: 1;
        }
      }
    }

    *:focus {
      outline: 0;
      outline: none;
    }

    .sliderDots {
      width: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: calc(20 * var(--REM) * -1);

      &.isPlusBottom {
        bottom: calc(80 * var(--REM) * -1);
      }

      ul {
        padding: 0;
        margin: 0;
        display: flex;

        li {
          display: block;
          width: calc(4 * var(--REM));
          height: calc(4 * var(--REM));
          border-radius: calc(32 * var(--REM));
          background-color: var(--color-gray1);
          opacity: 0.2;
          margin: 0 calc(2 * var(--REM));

          button {
            display: none;
          }

          &:global(.slick-active) {
            width: calc(12 * var(--REM));
            opacity: 1;
          }
        }
      }
    }

    .recipeContainer {
      position: absolute;
      bottom: calc(10 * var(--REM));
      left: calc(10 * var(--REM));
      padding: calc(8 * var(--REM)) calc(8 * var(--REM));
      border-radius: calc(100 * var(--REM));
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      div {
        width: 100%;
        font-size: calc(12 * var(--REM));
        display: contents;
        overflow: hidden;
        white-space: nowrap;

        span {
          color: var(--color-white);
          overflow: hidden;
          white-space: nowrap;
          display: inline-block;
          margin: 0 calc(2 * var(--REM)) 0 calc(4 * var(--REM));
          // width: 70px;

          -webkit-transition: all 0.7s linear 2s;
          -moz-transition: all 0.7s linear 2s;
          -o-transition: all 0.7s linear 2s;
          transition: all 0.7s linear 2s;

          // 유저들이 학습할때까지 임시 주석처리
          // &.recipeHide {
          //   width: 0px;
          //   margin: 0;
          // }
        }
      }
    }
  }

  .detailSection {
    margin-bottom: calc(10 * var(--REM));
    padding: calc(13 * var(--REM)) calc(10 * var(--REM));
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid var(--color-gray6);
    border-radius: calc(8 * var(--REM));

    .detailSectionText {
      font-size: calc(14 * var(--REM));
      columns: var(--color-gray3);
      font-weight: 500;
    }
    .detailSectionArrow {
      font-size: calc(14 * var(--REM));
    }
  }

  .iconSection {
    margin-bottom: calc(10 * var(--REM));
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .likeIcon {
      width: calc(24 * var(--REM));
      height: calc(24 * var(--REM));
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .shareIcon {
      width: calc(24 * var(--REM));
      height: calc(24 * var(--REM));
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .replySection {
    margin-bottom: calc(4 * var(--REM));
    display: flex;
    flex-direction: row;
    height: calc(20 * var(--REM));
    align-items: center;

    .replyProfile {
      margin-right: calc(4 * var(--REM));

      img {
        width: calc(18 * var(--REM));
        height: calc(18 * var(--REM));
        border-radius: 100%;
        border: calc(1 * var(--REM)) solid var(--color-white);

        &:not(:first-child) {
          margin-left: calc(10 * var(--REM) * -1);
        }
      }
    }
    .replyCount {
      color: var(--color-gray3);
      font-size: calc(14 * var(--REM));
      line-height: 100%;
    }
  }

  .titleSection {
    .title {
      margin-bottom: calc(4 * var(--REM));
      font-size: calc(20 * var(--REM));
      font-weight: 700;
      color: var(--color-gray1);
    }
    .content {
      font-size: calc(14 * var(--REM));
      line-height: calc(21 * var(--REM));
    }
  }
}
