.container {
  font-size: calc(14 * var(--REM));
  line-height: 130%;
  font-weight: 400;

  white-space: pre-wrap;
  word-break: keep-all;
}

.numberOfLines {
  max-width: 100%;
  overflow: hidden;

  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
