.containerDiv {
  position: relative;
  display: contents;
  overflow: hidden;

  .fixedTabs {
    display: flex;
    width: var(--DEVICE-WIDTH);
    background-color: var(--color-white);
    position: absolute;
    top: calc(45 * var(--REM));
  }
}

.container {
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  .topSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--color-gray8);
    padding-bottom: 26px;
    gap: 10px;

    .productImage {
      width: 100%;
      aspect-ratio: calc(375 / 388);
      position: relative;

      :global(.slick-list) {
        // overflow: hidden;

        :global(.slick-slide) > div {
          display: flex;

          img {
            width: 100%;
            aspect-ratio: calc(375 / 388);
            object-fit: cover;
          }
        }
      }

      *:focus {
        outline: 0;
        outline: none;
      }

      .sliderDots {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: calc(48 * var(--REM));

        ul {
          padding: 0;
          margin: 0;
          display: flex;

          li {
            display: block;
            width: calc(8 * var(--REM));
            height: calc(8 * var(--REM));
            border-radius: calc(32 * var(--REM));
            background-color: var(--color-gray1);
            opacity: 0.3;
            margin: 0 calc(4 * var(--REM));

            transition: all 0.3s ease;

            button {
              display: none;
            }

            &:global(.slick-active) {
              opacity: 1;
            }
          }
        }
      }

      .mainBadgeContainer {
        position: absolute;
        display: flex;
        margin: calc(20 * var(--REM));
        bottom: calc(27 * var(--REM));

        > div {
          margin-right: calc(2 * var(--REM));

          img {
            width: calc(50 * var(--REM));
            aspect-ratio: 1;
          }
        }
      }
    }

    .productInfo {
      margin: calc(-28 * var(--REM)) calc(16 * var(--REM)) 0;
      background-color: var(--color-white);
      border-radius: calc(16 * var(--REM));
      padding: calc(24 * var(--REM));
      position: relative;

      .partnerName {
        margin-bottom: calc(4 * var(--REM));
        font-size: calc(14 * var(--REM));
        line-height: 130%;
        font-weight: 500;
        color: var(--color-gray3);
      }

      .itemNameRow {
        display: flex;
        justify-content: space-between;

        .itemName {
          margin-bottom: calc(4 * var(--REM));
          font-size: calc(16 * var(--REM));
          line-height: 150%;
          color: var(--color-black);
        }

        .icon {
          width: calc(32 * var(--REM));
          height: calc(32 * var(--REM));
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .productPrice {
        display: flex;
        align-items: center;

        .percent {
          font-size: calc(24 * var(--REM));
          line-height: 130%;
          color: var(--color-red);
          margin-right: calc(8 * var(--REM));
          font-weight: 700;
        }

        .price {
          font-size: calc(24 * var(--REM));
          line-height: 130%;
          font-weight: 700;
        }

        .viewPrice {
          text-decoration: line-through;
          font-size: calc(16 * var(--REM));
          line-height: 150%;
          color: var(--color-gray5);
          margin-left: calc(8 * var(--REM));
        }
      }

      .couponDisabled {
        margin-top: calc(8 * var(--REM));
      }

      .productReviewInfo {
        margin-top: calc(8 * var(--REM));
        display: flex;
        align-items: center;

        .avgScore {
          margin: 0 calc(4 * var(--REM));
          font-size: calc(14 * var(--REM));
          line-height: 150%;
        }

        .reviewCnt {
          font-size: calc(14 * var(--REM));
          line-height: 150%;
          color: var(--color-gray4);
          margin-right: calc(4 * var(--REM));
        }
      }

      .badgeList {
        display: flex;
        margin-top: 16px;
        flex-wrap: wrap;
        gap: 4px;

        .badgeItem {
          border-radius: calc(8 * var(--REM));
          padding: calc(6 * var(--REM));
          background-color: var(--color-gray8);
          display: flex;
          align-items: center;

          svg {
            margin-right: calc(3 * var(--REM));
          }
          span {
            font-size: calc(12 * var(--REM));
            font-weight: 600;
            color: var(--color-gray3);
          }
        }
      }

      .line {
        margin: calc(16 * var(--REM)) 0;
        height: calc(1 * var(--REM));
        background-color: var(--color-gray7);
      }

      .deliverySection {
        display: flex;

        svg {
          margin-right: calc(8 * var(--REM));
        }

        .deliveryText {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: center;

          .deliveryTypeContent {
            display: flex;
            flex: 1;
            align-items: center;
            margin-bottom: calc(8 * var(--REM));

            .deliveryType {
              font-size: calc(14 * var(--REM));
              line-height: 150%;
              font-weight: 700;
              color: var(--color-green);
            }
            .dot {
              width: calc(4 * var(--REM));
              height: calc(4 * var(--REM));
              border-radius: 100%;
              background-color: var(--color-gray6);
              margin: 0 calc(6 * var(--REM));
            }
            .freeDeliveryInfo {
              font-size: calc(14 * var(--REM));
              line-height: 150%;
              font-weight: 600;
            }
          }
          .estimateText {
            font-size: calc(14 * var(--REM));
            line-height: 150%;

            span {
              color: var(--color-green);
              font-size: calc(14 * var(--REM));
              line-height: 150%;
              font-weight: 700;
            }
          }
        }
      }

      .pointAddInfo {
        display: flex;
        align-items: flex-start;
        margin-top: calc(18 * var(--REM));

        .pointIcon {
          display: flex;
          margin-right: calc(8 * var(--REM));
          align-items: center;
        }

        .slideContent {
          .fixedContent {
            display: flex;

            .estimatePoint {
              display: flex;
              align-items: center;
              font-size: calc(14 * var(--REM));
              line-height: 150%;
              color: var(--color-green);
              font-weight: 700;
              margin-right: calc(4 * var(--REM));
            }

            .chevron {
              display: flex;
              align-items: center;
              transform: rotate(360deg);
              transition: all 0.3s ease;

              &.open {
                transform: rotate(180deg);
              }
            }
          }
          .slideToggle {
            max-height: 0;
            overflow: hidden;

            transition: all 0.3s ease;

            .estimateOrderPoint {
              font-size: calc(14 * var(--REM));
              line-height: 150%;
              color: var(--color-gray4);
            }

            &.open {
              max-height: 100px;
              margin-bottom: calc(8 * var(--REM));
              margin-top: calc(8 * var(--REM));
            }
          }
        }
      }

      .stockAlert {
        display: flex;
        align-items: center;
        margin-top: calc(18 * var(--REM));

        svg {
          margin-right: calc(6 * var(--REM));
        }
        .red {
          color: var(--color-red500);
          font-size: calc(14 * var(--REM));
          font-weight: 700;
          line-height: 150%;
        }
        .dot {
          width: calc(4 * var(--REM));
          height: calc(4 * var(--REM));
          background: var(--color-gray6);
          margin: 0 calc(6 * var(--REM));
          border-radius: 100%;
        }
        .stock {
          font-size: calc(14 * var(--REM));
          line-height: 150%;
          font-weight: 500;
          color: var(--color-gray4);
        }
      }
    }

    .productGuarantee {
      margin: 0 calc(16 * var(--REM));
      background-color: var(--color-white);
      border-radius: calc(16 * var(--REM));
      padding: calc(20 * var(--REM)) calc(24 * var(--REM));
      position: relative;

      .touchContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .svg {
            display: flex;
            width: calc(16 * var(--REM));
            height: calc(16 * var(--REM));
            align-items: center;
            justify-content: center;
            margin-right: calc(4 * var(--REM));
          }
        }

        .chevron {
          display: flex;
          align-items: center;
          transform: rotate(270deg);
          transition: all 0.3s ease;

          &.open {
            transform: rotate(180deg);
          }
        }
      }

      .guaranteeInfo {
        transition: all 0.3s ease;
        max-height: 0px;
        overflow: hidden;

        &.open {
          max-height: 500px;
          margin-top: calc(13 * var(--REM));
        }
      }

      .dateRow {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .stockDate {
          min-width: calc(89 * var(--REM));
          margin-top: calc(12 * var(--REM));

          .title {
            margin-bottom: calc(4 * var(--REM));
          }
        }
      }
    }
  }

  .optionTabs {
    margin-bottom: calc(10 * var(--REM));
  }

  .reviewContainer {
    padding: calc(32 * var(--REM)) calc(20 * var(--REM));

    .titleContainer {
      display: flex;
      align-items: center;

      .title {
        font-size: calc(20 * var(--REM));
        line-height: 130%;
        font-weight: 700;
        margin-right: calc(8 * var(--REM));
      }
      .subTitle {
        font-size: calc(20 * var(--REM));
        line-height: 130%;
        margin-right: calc(8 * var(--REM));
        font-weight: 700;
        color: var(--color-green);
      }
    }
    .storeConateinr {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: calc(24 * var(--REM)) 0;
      position: relative;

      .starConatiner {
        margin-right: calc(8 * var(--REM));

        .emptyStar {
          position: absolute;
        }
        .fillStar {
          position: relative;
          width: calc(160 * var(--REM));
          > div {
            overflow: hidden;
          }
        }
      }
      .totalRating {
        font-size: calc(28 * var(--REM));
        line-height: 150%;
        font-weight: 700;

        span {
          color: var(--color-gray5);
        }
      }
    }
    .optionItem {
      display: flex;
      height: calc(32 * var(--REM));
      align-items: center;
      margin: calc(4 * var(--REM)) 0;

      .categoryTitle {
        width: calc(76 * var(--REM));
        font-size: calc(14 * var(--REM));
        line-height: 130%;
        font-weight: 600;
        color: var(--color-gray5);
      }
      .optionTitle {
        width: calc(124 * var(--REM));
        font-size: calc(14 * var(--REM));
        line-height: 130%;
        font-weight: 600;
      }
      .content {
        display: flex;
        align-items: center;
        flex: 1;

        .barContainer {
          position: relative;
          margin-right: calc(4 * var(--REM));
          flex: 1;

          .emptyBar {
            height: calc(6 * var(--REM));
            background: var(--color-gray6);
            border-radius: calc(100 * var(--REM));
          }
          .fillBar {
            position: absolute;
            top: 0;
            height: calc(6 * var(--REM));
            background: var(--color-orange);
            border-radius: calc(100 * var(--REM));
          }
        }
        .percent {
          text-align: right;
          width: calc(37 * var(--REM));
          font-size: calc(14 * var(--REM));
          line-height: 130%;
          font-weight: 500;
          color: var(--color-gray4);
        }
      }
    }
    .photoReviewContainer {
      display: flex;
      margin-bottom: calc(24 * var(--REM));

      .item {
        width: calc(calc((100% - calc(16 * var(--REM))) / 3));
        aspect-ratio: 1;
        margin-right: calc(8 * var(--REM));
        border-radius: calc(12 * var(--REM));
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          aspect-ratio: 1;
        }
        .buyCnt {
          font-size: calc(10 * var(--REM));
          line-height: 150%;
          position: absolute;
          margin: calc(8 * var(--REM));
          padding: calc(4 * var(--REM)) calc(6 * var(--REM));
          background-color: var(--color-gray8);
          border-radius: calc(4 * var(--REM));
          top: 0;
          left: 0;
        }

        .more {
          margin-right: 0;
          position: relative;

          .moreGray {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
            top: 0;

            color: var(--color-white);
            font-size: calc(14 * var(--REM));
            line-height: 150%;
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }
    .bottomReviewContainer {
      margin-bottom: calc(8 * var(--REM));
      .emptyReview {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: calc(24 * var(--REM)) 0 calc(56 * var(--REM));
        color: var(--color-gray5);
      }
    }
    .reviewBtn {
      display: flex;
      font-size: calc(16 * var(--REM));
      line-height: 150%;
      font-weight: 600;
      color: var(--color-gray3);
      align-items: center;
      justify-content: center;
      height: calc(56 * var(--REM));
      border: calc(1 * var(--REM)) solid var(--color-gray6);
      border-radius: calc(12 * var(--REM));

      svg {
        margin-left: calc(4 * var(--REM));
      }
    }
  }
  .recommandContainer {
    padding: calc(32 * var(--REM)) calc(20 * var(--REM)) calc(8 * var(--REM));

    .title {
      font-size: calc(20 * var(--REM));
      line-height: 130%;
      font-weight: 700;
      margin-bottom: calc(24 * var(--REM));
    }
    .listContainer {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: flex-start;

      .item {
        width: 50%;
        margin-bottom: calc(24 * var(--REM));

        &:nth-child(even) {
          > div {
            margin-left: calc(6 * var(--REM));
          }
        }
        &:nth-child(odd) {
          > div {
            margin-right: calc(6 * var(--REM));
          }
        }
      }
    }
  }
  .qnaContainer {
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) 0;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: calc(24 * var(--REM));
      .title {
        font-size: calc(20 * var(--REM));
        line-height: 130%;
        font-weight: 700;
      }
      .right {
        display: flex;

        .text {
          font-size: calc(14 * var(--REM));
          line-height: 130%;
          font-weight: 500;
          color: var(--color-gray5);
        }
      }
    }
    .emptyContent {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(60 * var(--REM));
      color: var(--color-gray5);
      font-size: calc(14 * var(--REM));
      line-height: 130%;
    }
    .qnaBtn {
      display: flex;
      font-size: calc(16 * var(--REM));
      line-height: 150%;
      font-weight: 600;
      color: var(--color-gray3);
      align-items: center;
      justify-content: center;
      height: calc(56 * var(--REM));
      border: calc(1 * var(--REM)) solid var(--color-gray6);
      border-radius: calc(12 * var(--REM));
      margin: calc(24 * var(--REM)) 0;

      svg {
        margin-left: calc(4 * var(--REM));
      }
    }
  }
}

.line {
  height: calc(1 * var(--REM));
  background-color: var(--color-gray7);
  margin: calc(12 * var(--REM)) 0 calc(24 * var(--REM));
}

.break {
  height: calc(10 * var(--REM));
  min-height: calc(10 * var(--REM));
  background-color: var(--color-gray7);
}

.toast {
  margin-bottom: calc(72 * var(--REM));
}

.footer {
  height: calc(72 * var(--REM));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 calc(20 * var(--REM));

  .btn {
    height: calc(56 * var(--REM));
    border-radius: calc(12 * var(--REM));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .likeBtn {
    width: calc(56 * var(--REM));
    border-width: calc(1 * var(--REM));
    border-color: var(--color-gray7);
    border-style: solid;
    margin-right: calc(8 * var(--REM));
  }

  .subscribeTempBtn {
    flex: 1;
    background-color: var(--color-white);
    border-width: calc(1 * var(--REM));
    border-color: var(--color-green);
    border-style: solid;
    margin-right: calc(4 * var(--REM));
    position: relative;

    .subscribeTooltipContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: calc(70 * var(--REM));
      left: 0;
      .text {
        display: flex;
        margin-right: calc(7 * var(--REM));
      }
      &.left {
        align-items: flex-start;
      }
      &.center {
        align-items: center;
      }
    }
    .noneSubscribeTooltipContainer {
      position: absolute;
      display: flex;
      flex-direction: column;
      bottom: calc(70 * var(--REM));
      align-items: center;
      flex-wrap: wrap;

      .text {
        display: flex;
        margin-right: 0;
      }
      &.left {
        left: 0;
      }
      &.right {
        right: calc(-50 * var(--REM));
      }
    }
  }

  .subscribeBtn {
    flex: 1;
    background-color: var(--color-white);
    border-width: calc(1 * var(--REM));
    border-color: var(--color-green);
    border-style: solid;
    margin: 0 calc(4 * var(--REM));
  }

  .buyBtn {
    flex: 1;
    background-color: var(--color-green);

    &.disabled {
      background-color: var(--color-gray6);
    }
  }
}

.fixedSignOnSection {
  width: var(--DEVICE-WIDTH);

  > div {
    position: relative;
    bottom: 0;
  }
}

.tabs {
  display: flex;
  height: calc(44 * var(--REM));
  align-items: center;
  justify-content: space-around;
  margin: 0;
  z-index: 2;

  .item {
    display: flex;
    flex: 1;
    height: calc(44 * var(--REM));
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-bottom: calc(2 * var(--REM)) solid var(--color-gray7);

    .title {
      font-size: calc(14 * var(--REM));
      line-height: 130%;
      font-weight: 500;
      color: var(--color-offblack);
    }

    .reviewCnt {
      font-size: calc(12 * var(--REM));
      line-height: 130%;
      font-weight: 500;
      color: var(--color-grayC6C6D4);
      margin-left: calc(4 * var(--REM));
    }

    &.active {
      border-bottom: calc(2 * var(--REM)) solid var(--color-green);

      .title {
        font-weight: 700;
        color: var(--color-green);
      }
    }
  }
}

.optionTabMenu {
  display: flex;
  flex: 1;
  height: calc(44 * var(--REM));
  border-bottom: calc(1 * var(--REM)) solid var(--color-gray7);
  padding: 0 calc(16 * var(--REM));
  min-width: fit-content;
  overflow-y: auto;

  .item {
    height: 100%;
    min-width: calc(44 * var(--REM));
    padding: 0 calc(12 * var(--REM));
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: calc(2 * var(--REM)) solid var(--color-white);

    .name {
      white-space: nowrap;
    }

    &.active {
      border-bottom: calc(2 * var(--REM)) solid var(--color-green);
    }
  }
}

.postSection {
  background-color: var(--color-white);
  margin-top: calc(10 * var(--REM));
  padding: calc(20 * var(--REM));

  .imgSection {
    display: flex;
    flex: 1;
    margin-top: calc(10 * var(--REM));
    border-radius: calc(8 * var(--REM));
    overflow: hidden;
    height: calc(224 * var(--REM));

    &.lengthMax {
      // height: calc(335 * var(--REM));
    }

    .item {
      display: flex;
      flex: 1;
      position: relative;
      overflow: hidden;

      img {
        flex: 1;
      }

      .imgText {
        display: flex;
        flex: 1;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: calc(10 * var(--REM));

        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
      }
    }

    .left {
      position: relative;
      display: flex;
      flex: 2;
      overflow: hidden;
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: calc(4 * var(--REM));
      overflow: hidden;

      img {
        aspect-ratio: 1;
      }

      .item:nth-child(2) {
        margin-top: calc(4 * var(--REM));
      }
    }
  }
}

.topReviewSection {
  padding: 0 calc(16 * var(--REM));
  align-items: center;

  .container {
    display: flex;
    flex: 1;
    background-color: var(--color-white);
    border-radius: calc(16 * var(--REM));
    padding: calc(20 * var(--REM));

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .sectionTitle {
          margin-right: calc(4 * var(--REM));
        }
      }
    }

    .contentContainer {
      display: flex;
      margin-top: calc(16 * var(--REM));

      img {
        width: calc(92 * var(--REM));
        aspect-ratio: 1;
        border-radius: calc(8 * var(--REM));
      }

      .textContainer {
        display: flex;
        flex: 1;
        margin-left: calc(12 * var(--REM));
        justify-content: space-between;
        flex-direction: column;

        .bottom {
          display: flex;
          align-items: center;

          .badge {
            display: flex;
            background-color: var(--color-white);
            border-radius: calc(4 * var(--REM));
            align-items: center;
            justify-content: center;
            padding: calc(4 * var(--REM)) calc(5 * var(--REM));
          }
          .nickname {
            padding: 0 calc(8 * var(--REM));
          }
        }
      }
    }
  }
}

.noticeItem {
  display: flex;
  flex-direction: column;
  margin: calc(20 * var(--REM)) calc(20 * var(--REM)) calc(12 * var(--REM))
    calc(20 * var(--REM));
  padding: calc(16 * var(--REM)) calc(20 * var(--REM));
  border-radius: calc(16 * var(--REM));
  background-color: var(--color-gray8);

  .titleContainer {
    display: flex;
    align-items: center;

    .svg {
      width: calc(24 * var(--REM));
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: calc(4 * var(--REM));
    }
  }
  .contentContainer {
    .item {
      margin-top: calc(4 * var(--REM));
    }
  }
}

.checkPointNew {
  padding: calc(20 * var(--REM));
  margin-top: calc(12 * var(--REM));
  padding-bottom: calc(8 * var(--REM));
  overflow: hidden;

  .titleContainer {
    display: flex;
    margin-bottom: calc(8 * var(--REM));
    align-items: center;
    justify-content: center;

    .svg {
      display: flex;
      width: calc(32 * var(--REM));
      height: calc(26 * var(--REM));
      justify-content: cetner;
      align-items: center;
      margin-right: calc(4 * var(--REM));
    }
  }

  .contentContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: calc(15 * var(--REM));

    .item {
      display: flex;
      margin: calc(5 * var(--REM)) 0;
      width: 100%;
      height: calc(114 * var(--REM));
      background-color: var(--color-gray8);
      border-radius: calc(4 * var(--REM));
      overflow: hidden;

      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        width: 100%;
        height: 100%;
      }
      .content {
        margin-left: calc(17 * var(--REM));
      }
    }
  }
}

.checkPointItem {
  padding: calc(20 * var(--REM));
  padding-bottom: 0;

  .container {
    padding: calc(24 * var(--REM)) calc(20 * var(--REM));
    background-color: var(--color-ivory);
    border-radius: calc(24 * var(--REM));
    overflow: hidden;
    position: relative;

    .svg {
      position: absolute;
      top: calc(-20 * var(--REM));
      left: calc(40 * var(--REM));
    }

    .titleContainer {
      display: flex;
      margin-bottom: calc(8 * var(--REM));

      .svgContainer {
        display: flex;
        width: calc(32 * var(--REM));
        height: calc(26 * var(--REM));
        align-items: center;
        justify-content: center;
        margin-right: calc(4 * var(--REM));
      }
    }
    .item {
      margin-top: calc(12 * var(--REM));
      .itemTitle {
        margin-bottom: calc(4 * var(--REM));
      }
    }
  }
}

.imgDetailItem {
  padding: 0 calc(20 * var(--REM)) calc(40 * var(--REM));

  .item {
    padding-top: calc(40 * var(--REM));

    img {
      width: 100%;
      flex: 1;
      border-radius: calc(4 * var(--REM));
    }
    .contentContainer {
      display: flex;
      flex-direction: column;
      padding: calc(24 * var(--REM)) 0 0;

      .title {
        margin-bottom: calc(16 * var(--REM));
      }
    }
    .recipeContainer {
      padding-top: calc(40 * var(--REM));
    }
  }
}

.recipeItem {
  padding: calc(20 * var(--REM)) 0;

  .recipeTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 calc(20 * var(--REM));

    .svg {
      margin-bottom: calc(4 * var(--REM));
    }
  }
  .recipeContentContainer {
    margin-top: calc(24 * var(--REM));

    .imgContainer {
      position: relative;

      .recipeSlideItem {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        width: var(--DEVICE-WIDTH);

        img {
          width: 100%;
          aspect-ratio: 1;
        }
        .content {
          padding: calc(38 * var(--REM)) calc(20 * var(--REM)) 0;
          overflow: hidden;
          word-break: keep-all;
        }
      }

      .sliderDots {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: calc(
          var(--DEVICE-WIDTH) - calc(40 * var(--REM)) + calc(16 * var(--REM))
        );

        ul {
          padding: 0;
          margin: 0;
          display: flex;

          li {
            display: block;
            width: calc(6 * var(--REM));
            height: calc(6 * var(--REM));
            border-radius: calc(32 * var(--REM));
            background-color: var(--color-gray2);
            opacity: 0.2;
            margin: 0 calc(5 * var(--REM));

            transition: all 0.3s ease;

            button {
              display: none;
            }

            &:global(.slick-active) {
              opacity: 1;
              width: calc(20 * var(--REM));
            }
          }
        }
      }
      *:focus {
        outline: 0;
        outline: none;
      }
    }
  }
}

.interviewItem {
  padding-top: calc(40 * var(--REM));

  img {
    width: 100%;
    aspect-ratio: 1;
    border-radius: calc(4 * var(--REM));
  }

  .item {
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM));

    .title {
      margin-bottom: calc(16 * var(--REM));
    }
  }
}

.interview2Item {
  margin: calc(20 * var(--REM)) 0 calc(10 * var(--REM));
  padding: 0 calc(20 * var(--REM));

  .box {
    padding: calc(20 * var(--REM));
    background-color: var(--color-gray8);
    border-radius: calc(24 * var(--REM));

    .titleContainer {
      display: flex;
      align-items: center;
      margin-bottom: calc(16 * var(--REM));

      .title {
        margin-right: calc(5 * var(--REM));
      }
    }
    .contentContainer {
      display: flex;

      .imgContainer {
        margin-right: calc(12 * var(--REM));

        img {
          width: calc(80 * var(--REM));
          aspect-ratio: 1;
          border-radius: 100%;
        }
      }
    }
  }
}

.materialItem {
  position: relative;
  margin-bottom: calc(10 * var(--REM));
  padding: 0 calc(20 * var(--REM));

  .box {
    background-color: var(--color-gray8);
    border-radius: calc(24 * var(--REM));
    padding: calc(24 * var(--REM)) calc(20 * var(--REM));

    .titleContainer {
      display: flex;
      align-items: center;

      .title {
        margin-right: calc(4 * var(--REM));
      }
      svg {
        height: calc(20 * var(--REM));
      }
    }
    .contentContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(16 * var(--REM));

      .item {
        display: flex;
        align-items: center;
        background: var(--color-white);
        border-radius: calc(6 * var(--REM));
        padding: calc(6 * var(--REM)) calc(10 * var(--REM));
        margin: calc(4 * var(--REM)) calc(4 * var(--REM)) 0 0;

        .dotContainer {
          display: flex;
          margin-right: calc(4 * var(--REM));

          .dot {
            width: calc(8 * var(--REM));
            aspect-ratio: 1;
            border-radius: 100%;
            margin-right: calc(2 * var(--REM));
          }
        }
      }
    }
    .bottom {
      display: flex;
      margin-top: calc(16 * var(--REM));

      .bottomItem {
        display: flex;
        align-items: center;
        margin-right: calc(16 * var(--REM));

        .dot {
          width: calc(8 * var(--REM));
          aspect-ratio: 1;
          border-radius: 100%;
          margin-right: calc(6 * var(--REM));
        }
      }
    }
  }
}

.itemOriginItem {
  padding: 0 calc(20 * var(--REM));
  margin-bottom: 20px;
  position: relative;

  .container {
    display: flex;
    background-color: var(--color-gray8);
    border-radius: calc(24 * var(--REM));
    padding: calc(24 * var(--REM)) calc(20 * var(--REM));
    position: relative;

    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .chevron {
        svg {
          transition: all 0.3s ease;
        }
      }
      .right {
        display: flex;
        svg {
          margin-right: calc(4 * var(--REM));
        }
      }
    }

    .contentContainer {
      margin-top: calc(16 * var(--REM));
      max-height: 500px;
      overflow: hidden;
      transition: all 0.3s ease;

      &:not(.open) {
        margin: 0;
        max-height: 0px;
      }

      .title {
        margin-top: calc(12 * var(--REM));
      }
      .imgContainer {
        border-radius: calc(16 * var(--REM));
        overflow: hidden;

        .row {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .left {
            display: flex;
            flex-wrap: wrap;
            margin-left: calc(4 * var(--REM));
          }

          &.one {
            .item {
              flex: 1;
              height: calc(145 * var(--REM));
            }
          }
          &.two {
            .item {
              flex: 1;
              height: calc(145 * var(--REM));
            }
            .left {
              flex: 1;
            }
          }
          &.three,
          &.five {
            .item:nth-child(1) {
              flex: 2;
              height: calc(196 * var(--REM));
            }
            .left {
              flex: 1;
              flex-direction: column;
              height: calc(196 * var(--REM));

              .item:nth-child(1) {
                flex: 1;
              }
              .item:nth-child(2) {
                flex: 1;
                margin-top: calc(4 * var(--REM));
              }
            }
          }
          &.four {
            .item:nth-child(1) {
              flex: 1;
              height: calc(145 * var(--REM));
            }
            .left {
              flex: 1;
              .item:nth-child(1) {
                height: calc(145 * var(--REM));
              }
            }
          }
        }
        .bottom {
          display: flex;

          &.four,
          &.five {
            margin-top: calc(4 * var(--REM));
            .item {
              flex: 1;
              height: calc(145 * var(--REM));
            }
            .item:nth-child(1) {
              margin-right: calc(4 * var(--REM));
            }
          }
        }
        .item {
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .tootipContainer {
    display: flex;
    position: absolute;
    top: calc(4 * var(--REM));
    align-items: center;
    flex: 1;
    width: fit-content;
  }
}

.compositionItem {
  padding: 0 calc(20 * var(--REM));
  margin: 20px 0 calc(10 * var(--REM)) 0;

  .container {
    padding: calc(24 * var(--REM)) 0;
    background-color: var(--color-gray8);
    border-radius: calc(16 * var(--REM));

    .titleContainer {
      display: flex;
      align-items: flex-end;
      padding: 0 calc(20 * var(--REM));
      margin-bottom: calc(8 * var(--REM));

      .title {
        margin-right: calc(4 * var(--REM));
      }
      .subTitle {
        margin-right: calc(4 * var(--REM));
      }
    }
    .tabConatiner {
      display: flex;
      margin: calc(8 * var(--REM)) calc(20 * var(--REM)) 0;
      justify-content: space-around;
      border-radius: calc(8 * var(--REM));
      overflow: hidden;
      background-color: var(--color-gray7);

      .tabItem {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: calc(40 * var(--REM));

        &.active {
          border-radius: calc(10 * var(--REM));
          overflow: hidden;
          background-color: var(--color-white);
        }
      }
    }
    .contentContainer {
      display: flex;
      margin: calc(8 * var(--REM)) calc(20 * var(--REM)) 0;
      padding: calc(4 * var(--REM)) calc(20 * var(--REM)) 0;
      background: var(--color-white);
      border-radius: calc(12 * var(--REM));

      .contentContainer2 {
        display: flex;
        flex: 1;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          align-items: center;
          padding: calc(12 * var(--REM)) 0;
          width: 50%;

          &.isBorder {
            border-bottom: calc(1 * var(--REM)) solid var(--color-gray7);
          }
          &.full {
            width: 100%;
          }
          > div {
            display: flex;
            justify-content: space-between;
          }
          &.isLeft:not(.full) > div {
            padding-right: calc(9 * var(--REM));
          }
          &:not(.isLeft):not(.full) > div {
            padding-left: calc(9 * var(--REM));
          }

          .contentRow {
            display: flex;
            align-items: center;
            .percent {
              margin-left: calc(4 * var(--REM));
            }
          }
        }

        .moreBtn {
          display: flex;
          width: 100%;
          height: calc(44 * var(--REM));
          align-items: center;
          justify-content: center;
          background-color: var(--color-gray8);
          border-radius: calc(8 * var(--REM));
          overflow: hidden;
          margin-bottom: calc(12 * var(--REM));
          gap: calc(4 * var(--REM));
        }
      }
    }
    .subContent {
      margin: calc(8 * var(--REM)) calc(20 * var(--REM)) 0;
    }
  }
}

.feedAmountItem {
  padding: 0 calc(20 * var(--REM));
  margin-bottom: calc(16 * var(--REM));

  .container {
    background: var(--color-gray8);
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) calc(8 * var(--REM));
    border-radius: calc(16 * var(--REM));

    .titleContainer {
      display: flex;
      align-items: flex-end;

      .title {
        margin-right: calc(4 * var(--REM));
      }
    }
    .listContainer {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: calc(16 * var(--REM));

      .item {
        margin-bottom: calc(16 * var(--REM));
        width: calc(100% / 3 - calc(2 * var(--REM)));
        border-color: var(--color-gray6);
        border-width: calc(1 * var(--REM));
        border-style: solid;
        overflow: hidden;

        &.radiusLeft {
          border-top-left-radius: calc(8 * var(--REM));
          border-bottom-left-radius: calc(8 * var(--REM));

          .titleContainer {
            border-top-left-radius: calc(8 * var(--REM));
          }
          &:not(.radiusRight) {
            border-right-width: 0;
          }
        }
        &.radiusRight {
          border-top-right-radius: calc(8 * var(--REM));
          border-bottom-right-radius: calc(8 * var(--REM));

          .titleContainer {
            border-top-right-radius: calc(8 * var(--REM));
          }
          &:not(.radiusLeft):nth-child(3n + 0) {
            border-left-width: 0;
          }
        }

        .titleContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          height: calc(40 * var(--REM));
          border-color: var(--color-gray6);
          border-width: 0;
          border-bottom-width: calc(1 * var(--REM));
          border-style: solid;
          background: var(--color-white);
        }

        .contentContainer {
          height: calc(40 * var(--REM));
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .calorieContainer {
      padding-bottom: calc(4 * var(--REM));
    }
    .discriptionContainer {
      padding-bottom: calc(16 * var(--REM));
    }
  }
}

.feedItem {
  padding: 0 calc(20 * var(--REM));
  margin-bottom: calc(16 * var(--REM));

  .container {
    background-color: var(--color-gray8);
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) calc(8 * var(--REM));
    border-radius: calc(16 * var(--REM));

    .listContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: calc(16 * var(--REM));

      .item {
        display: flex;
        flex: 1;
        margin-bottom: calc(8 * var(--REM));

        .iconContainer {
          height: calc(24 * var(--REM));
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: calc(8 * var(--REM));
        }

        .textContainer {
          flex: 1;
          .subTitle {
            margin-top: calc(2 * var(--REM));
          }
        }

        &.close {
          .title {
            color: var(--color-red);
          }
        }
      }
    }
  }
}

.informationItem {
  padding: 0 calc(16 * var(--REM)) 0;

  .container {
    background-color: var(--color-white);
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) calc(16 * var(--REM));
    border-radius: calc(16 * var(--REM));

    .title {
      margin-bottom: calc(16 * var(--REM));
    }
    .item {
      display: flex;
      margin-bottom: calc(12 * var(--REM));

      .itemTitle {
        width: calc(93 * var(--REM));
      }
      .contentContainer {
        flex: 1;
      }
    }
  }
}

.storageItem {
  padding: 0 calc(20 * var(--REM));
  margin-bottom: calc(16 * var(--REM));

  .container {
    background-color: var(--color-gray8);
    padding: calc(24 * var(--REM)) calc(20 * var(--REM)) calc(8 * var(--REM));
    border-radius: calc(16 * var(--REM));

    .listContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-top: calc(16 * var(--REM));

      .item {
        display: flex;
        flex: 1;
        margin-bottom: calc(8 * var(--REM));

        .iconContainer {
          height: calc(24 * var(--REM));
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: calc(8 * var(--REM));
        }

        .textContainer {
          flex: 1;

          .subTitle {
            margin-top: calc(2 * var(--REM));
          }
        }

        &.close {
          .title {
            color: var(--color-red);
          }
        }
      }
    }
  }
}

.materialModal {
  width: var(--DEVICE-WIDTH);
  height: 100%;

  .wrapper {
    background-color: var(--color-gray8);
    border-top-left-radius: calc(16 * var(--REM));
    border-top-right-radius: calc(16 * var(--REM));
    padding: calc(32 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM));

    .titleRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .contentRow {
      display: flex;
      background-color: var(--color-white);
      padding: calc(16 * var(--REM)) calc(12 * var(--REM));
      border-radius: calc(12 * var(--REM));
      margin: calc(10 * var(--REM)) 0 calc(8 * var(--REM));

      svg {
        margin-right: calc(12 * var(--REM));
      }
    }
    .bottomBtn {
      height: calc(56 * var(--REM));
      border-radius: calc(12 * var(--REM));
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: calc(16 * var(--REM));
      background-color: var(--color-gray7);
    }
  }
}

.productBuyModal {
  .modal {
    max-width: var(--DEVICE-WIDTH);
    width: var(--DEVICE-WIDTH);
    background: var(--color-gray8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: calc(16 * var(--REM)) calc(16 * var(--REM)) 0 0;
    position: relative;

    .container {
      display: flex;
      width: calc(100% - 40px);
      padding: calc(32 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM));

      .topContainer {
        .priceRow {
          display: flex;
          justify-content: space-between;
          margin-top: calc(17 * var(--REM));
          .price {
            display: flex;
          }
        }
        .btnContainer {
          display: flex;
          border: calc(1 * var(--REM)) solid var(--color-gray6);
          border-radius: calc(4 * var(--REM));

          .countChangeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(28 * var(--REM));
            height: calc(28 * var(--REM));
          }
          .count {
            width: calc(42 * var(--REM));
            border: 0 solid var(--color-gray6);
            border-left-width: calc(1 * var(--REM));
            border-right-width: calc(1 * var(--REM));
          }
        }
        .stockAlert {
          display: flex;
          align-items: center;
          justify-content: "flex-end";
          margin-top: calc(4 * var(--REM));

          svg {
            margin-right: calc(2 * var(--REM));
          }
        }
      }

      .line {
        height: calc(1 * var(--REM));
        background: var(--color-gray7);
        margin: calc(16 * var(--REM)) 0;
      }
      .nextStockDate {
        display: flex;
        min-height: calc(38 * var(--REM));
        margin-bottom: calc(8 * var(--REM));

        .title {
          display: flex;
          flex-direction: column;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
          height: calc(36 * var(--REM));
          justify-items: center;
        }
        .box {
          display: flex;
          flex: 1;
          background: var(--color-redFFF4F4);
          padding: calc(10 * var(--REM)) calc(12 * var(--REM))
            calc(12 * var(--REM));
          border-radius: calc(12 * var(--REM));

          svg {
            margin-right: calc(4 * var(--REM));
          }
          .text {
            flex: 1;
            .textTitle {
              display: flex;
              margin-bottom: calc(4 * var(--REM));
            }
          }
        }
      }
      .stockDate {
        display: flex;
        align-items: center;
        height: calc(36 * var(--REM));
        margin-bottom: calc(8 * var(--REM));

        .title {
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
        }
      }
      .rowItem {
        display: flex;
        height: calc(36 * var(--REM));
        align-items: center;
        margin-bottom: calc(8 * var(--REM));
        .title {
          display: flex;
          align-items: center;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
        }
        .content {
          display: flex;
          align-items: center;
          svg {
            margin-right: calc(6 * var(--REM));
          }
        }
      }
      .deliveryFeeContainer {
        display: flex;
        margin-bottom: calc(28 * var(--REM));
        .title {
          display: flex;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
        }
        .deliveryFee {
          margin-bottom: calc(4 * var(--REM));
        }
      }

      .bottomContainer {
        display: flex;
        margin-bottom: calc(8 * var(--REM));

        .btn {
          height: calc(56 * var(--REM));
          border-radius: calc(12 * var(--REM));
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          margin-top: calc(16 * var(--REM));
          background-color: var(--color-green);
        }
      }
    }
  }
}

.productBuySubscribeModal {
  .modal {
    width: var(--DEVICE-WIDTH);
    max-width: var(--DEVICE-WIDTH);
    position: relative;
    background-color: var(--color-gray8);
    border-top-left-radius: calc(16 * var(--REM));
    border-top-right-radius: calc(16 * var(--REM));

    .container {
      width: calc(100% - 40px);
      padding: calc(32 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM));

      .topContainer {
        .priceRow {
          display: flex;
          justify-content: space-between;
          margin-top: calc(17 * var(--REM));
          .price {
            display: flex;
          }
        }
        .btnContainer {
          display: flex;
          border: calc(1 * var(--REM)) solid var(--color-gray6);
          border-radius: calc(4 * var(--REM));

          .countChangeBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: calc(28 * var(--REM));
            height: calc(28 * var(--REM));
          }
          .count {
            width: calc(42 * var(--REM));
            border: 0 solid var(--color-gray6);
            border-left-width: calc(1 * var(--REM));
            border-right-width: calc(1 * var(--REM));
          }
        }
        .stockAlert {
          display: flex;
          align-items: center;
          justify-content: "flex-end";
          margin-top: calc(4 * var(--REM));

          svg {
            margin-right: calc(2 * var(--REM));
          }
        }
      }

      .line {
        height: calc(1 * var(--REM));
        background: var(--color-gray7);
        margin: calc(16 * var(--REM)) 0;
      }
      .releaseCycleContainer {
        display: flex;
        min-height: calc(36 * var(--REM));
        margin-bottom: calc(8 * var(--REM));

        .title {
          display: flex;
          flex-direction: column;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
          height: calc(36 * var(--REM));
          justify-content: center;
        }
        .content {
          display: flex;
          flex-direction: column;
          flex: 1;

          .selectList {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-bottom: calc(8 * var(--REM));

            .item {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
              height: calc(36 * var(--REM));
              border-width: calc(1 * var(--REM));
              border-style: solid;
              border-radius: calc(8 * var(--REM));
              border-color: var(--color-gray4);
              opacity: 0.3;

              &:not(:first-child) {
                margin-left: calc(4 * var(--REM));
              }
              &.active {
                opacity: 1;
                border-color: var(--color-green);
              }
            }
          }
          .box {
            display: flex;
            flex: 1;
            background: var(--color-redFFF4F4);
            padding: calc(10 * var(--REM)) calc(12 * var(--REM))
              calc(12 * var(--REM));
            border-radius: calc(12 * var(--REM));

            svg {
              margin-right: calc(4 * var(--REM));
            }
            .text {
              flex: 1;
              .textTitle {
                display: flex;
                margin-bottom: calc(4 * var(--REM));
              }
            }
          }
        }
      }
      .rowItem {
        display: flex;
        height: calc(36 * var(--REM));
        align-items: center;
        margin-bottom: calc(8 * var(--REM));
        .title {
          display: flex;
          align-items: center;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
        }
        .content {
          display: flex;
          align-items: center;
          svg {
            margin-right: calc(6 * var(--REM));
          }
        }
      }
      .deliveryFeeContainer {
        display: flex;
        margin-bottom: calc(28 * var(--REM));
        .title {
          display: flex;
          width: calc(80 * var(--REM));
          margin-right: calc(7 * var(--REM));
        }
        .deliveryFee {
          margin-bottom: calc(4 * var(--REM));
        }
      }

      .bottomContainer {
        display: flex;
        margin-bottom: calc(8 * var(--REM));

        .btn {
          height: calc(56 * var(--REM));
          border-radius: calc(12 * var(--REM));
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          margin-top: calc(16 * var(--REM));
          background-color: var(--color-green);
        }
      }
    }
  }
}

.productDetailSignonCouponModal {
  .wrap {
    width: var(--DEVICE-WIDTH);
    height: var(--DEVICE-HEIGHT);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .modal {
      max-width: var(--DEVICE-WIDTH);
      width: calc(var(--DEVICE-WIDTH) - calc(40 * var(--REM)));
      background: var(--color-white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: calc(32 * var(--REM)) 0 calc(20 * var(--REM));
      border-radius: calc(16 * var(--REM));
      position: relative;

      .contents {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: calc(100% - calc(40 * var(--REM)));
        align-items: center;
        justify-content: center;

        .subTitle {
          margin: calc(8 * var(--REM)) 0;
        }
        .btn {
          margin-top: calc(18 * var(--REM));
          height: calc(56 * var(--REM));
          border-radius: calc(12 * var(--REM));
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-green);
          width: 100%;
        }
      }
    }
  }
}

.imagePlayModal {
  .wrap {
    width: var(--DEVICE-WIDTH);
    height: var(--DEVICE-HEIGHT);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .imgSection {
      position: absolute;
      top: 0;
      width: var(--DEVICE-WIDTH);
      height: calc(var(--DEVICE-HEIGHT) - calc(72 * var(--REM)));

      > div {
        position: relative;
      }

      :global(.slick-list) {
        // overflow: hidden;

        :global(.slick-slide) > div {
          display: flex;

          .item {
            position: relative;
            width: var(--DEVICE-WIDTH);
            height: calc(var(--DEVICE-HEIGHT) - calc(72 * var(--REM)));

            img {
              width: var(--DEVICE-WIDTH);
              height: calc(var(--DEVICE-HEIGHT) - calc(72 * var(--REM)));
            }
            .imgContainer {
              width: 100%;
            }
            .touchArea {
              display: flex;
              flex: 1;
              position: fixed;
              width: var(--DEVICE-WIDTH);
              height: calc(var(--DEVICE-HEIGHT) - calc(72 * var(--REM)));
              top: 0;
              > div {
                flex: 1;
              }
            }
          }
        }
      }
    }

    .topContent {
      position: absolute;
      top: 0;
      width: var(--DEVICE-WIDTH);

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: var(--layout-header-height);
        width: var(--DEVICE-WIDTH);

        .backIcon {
          width: calc(44 * var(--REM));
          height: calc(44 * var(--REM));
          display: flex;
          justify-content: center;
          align-items: center;
          padding: calc(15 * var(--REM)) calc(8 * var(--REM));
        }
        .title {
          flex: 1;
        }
        .right {
          width: calc(60 * var(--REM));
        }
      }

      .playLine {
        display: flex;
        justify-content: space-around;
        width: var(--DEVICE-WIDTH);

        .item {
          display: flex;
          flex: 1;

          &:first-child {
            margin-right: calc(2 * var(--REM));
          }
          &:not(:first-child, :last-child) {
            margin: 0 calc(2 * var(--REM));
          }
          &:last-child {
            margin-left: calc(2 * var(--REM));
          }
          .activeLine {
            display: flex;
            height: calc(2 * var(--REM));
            width: 100%;
            background: var(--color-white);
            overflow: hidden;

            .animateLine {
              width: 0%;
              background: var(--color-green);
              height: calc(2 * var(--REM));
              transition: none;
            }
            &.loading {
              .animateLine {
                width: 100%;
                transition: width 2.5s ease;
              }
            }
            &.active {
              .animateLine {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .footerContainer {
    position: absolute;
    bottom: 0;
    width: var(--DEVICE-WIDTH);

    .bottomFooter {
      width: var(--DEVICE-WIDTH);
      background-color: var(--color-white);
    }

    .bottom {
      width: calc(var(--DEVICE-WIDTH) - calc(40 * var(--REM)));
      padding: 0 calc(20 * var(--REM)) calc(32 * var(--REM));

      .partnerRow {
        display: flex;
        margin-top: calc(16 * var(--REM));
        align-items: center;

        .partnerImg {
          width: calc(32 * var(--REM));
          aspect-ratio: 1;
          border-radius: 100%;
          margin-right: calc(4 * var(--REM));
        }
        .emptyImg {
          width: calc(32 * var(--REM));
          height: calc(32 * var(--REM));
          border-radius: 100%;
          margin-right: calc(4 * var(--REM));
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-gray7);
        }
      }
    }
  }
  .radioHidden {
    display: none;
  }
}

.headerCartIcon {
  width: calc(44 * var(--REM));
  height: calc(44 * var(--REM));
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemDmbModal {
  width: var(--DEVICE-WIDTH);
  height: 100%;

  .wrapper {
    background-color: var(--color-white);
    border-top-left-radius: calc(16 * var(--REM));
    border-top-right-radius: calc(16 * var(--REM));
    padding: calc(32 * var(--REM)) calc(20 * var(--REM)) calc(20 * var(--REM));

    .titleRow {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
        align-items: center;

        .subTitle {
          margin-left: calc(4 * var(--REM));
        }
      }
    }
    .content {
      display: flex;
      flex: 1;
      background-color: var(--color-white);
      padding: calc(16 * var(--REM)) 0;
      border-radius: calc(12 * var(--REM));
      max-height: calc(466 * var(--REM));
      height: 100%;
      overflow-x: auto;

      .dmbContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .dmbItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: calc(12 * var(--REM)) 0;
          width: 50%;

          &.isBorder {
            border-bottom: calc(1 * var(--REM)) solid var(--color-gray7);
          }
          &.full {
            width: 100%;
          }
          > div {
            display: flex;
            justify-content: space-between;
          }
          &.isLeft:not(.full) > div {
            padding-right: calc(9 * var(--REM));
          }
          &:not(.isLeft):not(.full) > div {
            padding-left: calc(9 * var(--REM));
          }

          .contentRow {
            display: flex;
            align-items: center;

            .percent {
              margin-left: calc(4 * var(--REM));
            }
          }
        }
      }
    }
    .description {
      margin: calc(12 * var(--REM)) 0 0 0;
    }
    .bottomBtn {
      height: calc(56 * var(--REM));
      border-radius: calc(12 * var(--REM));
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: calc(16 * var(--REM));
      background-color: var(--color-gray7);
    }
  }
}
