.wrap {
  display: flex;
  flex: 1;
  height: 100svh;
  justify-content: center;
  background: var(--color-green);
  overflow: hidden;
}

.center {
  /* flex: auto; */
  max-width: var(--DEVICE-WIDTH);
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}

.left {
  /* flex: 1; */
  margin-right: 166px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .rightCont {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: var(--color-white);

    img {
      height: calc(210 * var(--REM));
    }

    h3 {
      text-align: center;
    }

    .rightBtnWrap {
      display: flex;
      align-items: center;

      .button {
        display: inline-block;
        padding: calc(18 * var(--REM)) calc(32 * var(--REM));
        border-radius: calc(12 * var(--REM));
        border: 1px solid var(--color-white);
        font-size: calc(16 * var(--REM));
        font-weight: 700;
        text-decoration: none;

        &:first-child {
          margin-right: calc(8 * var(--REM));
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .wrap {
    background: var(--color-white);
  }

  .left,
  .right {
    display: none;
  }

  .center {
    margin-right: 0;
  }
}

.right {
  /* flex: 1; */
}

@media (max-device-width: 540px) {
  .center {
    height: calc(100vh - calc(100vh - 100%));
  }
}
